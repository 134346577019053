import { Injectable, Injector } from '@angular/core';
import { SectionManagerService } from '../../../core/services/section-manager.service';
import { DistributorProps, DistributorSection } from '@pages/distributor/enums/distributor-manager.enum';
import { DistributorManagerConstant } from '@pages/distributor/constant/distributor-manager.constant';
import { Observable, map } from 'rxjs';
import { DistributorManagerEndpoints } from '@pages/distributor/constant/distributor-manager.endpoints.constant';
import { RouteParams } from '../../../core/enums/route.enum';
import { DistributorApi } from '@pages/distributor/types/distributor.type';
import { ApiProps } from '../../../core/enums/api.enum';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { DistributorApiProps } from '../enums/distributor.enum';
import { ApiResponse } from 'app/core/types/api.type';
import { DropdownService } from '@components/atoms/form-inputs/components/next-dropdown/services/dropdown.service';
import { ProductLineProps } from '@pages/distributor/enums/product-lines.enum';
import { ProductLinesConstant } from '../constant/product-lines.constant';
import { ProductLineType } from 'app/core/enums/status.enum';

@Injectable({
  providedIn: 'root'
})
export class DistributorService extends SectionManagerService<DistributorSection> {
  constructor(
    injector: Injector,
    private _dropdownService: DropdownService
  ) {
    super(injector, DistributorManagerConstant.endpointItemBySection);
  }

  get(id: number): Observable<DistributorApi> {
    return this._backendService.get(DistributorManagerEndpoints.config.DISTRIBUTOR.ITEM, {
      urlParams: { [RouteParams.ID]: id },
      resultWithinProp: [ApiProps.RESULT]
    });
  }

  getList() {
    return this._backendService.get(DistributorManagerEndpoints.config.DISTRIBUTOR.LIST).pipe(
      map((response: ApiResponse<any>) => {
        return NextObjectHelper.getPropertyFromObject(
          response,
          [ApiProps.RESULT, DistributorApiProps.DISTRIBUTORS],
          []
        );
      })
    );
  }

  getBriefList() {
    return this.getList().pipe(
      map(distributorsList => {
        return distributorsList.map((response: any) => {
          return {
            id: response.generalInformation.id,
            name: response.generalInformation.name
          };
        });
      })
    );
  }

  getDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.DISTRIBUTOR.LIST,
      {
        labelProp: [DistributorSection.GENERAL_INFORMATION, DistributorProps.ID],
        labelExtraProp: [DistributorSection.GENERAL_INFORMATION, DistributorProps.NAME],
        valueProp: [DistributorSection.GENERAL_INFORMATION, DistributorProps.ID]
      },
      { resultWithinProp: [ApiProps.RESULT, DistributorApiProps.DISTRIBUTORS] }
    );
  }

  getProductLineByDistributorId(id: number) {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.PRODUCT_LINES_BY_DISTRIBUTOR,
      {
        labelProp: ProductLineProps.PL_NAME,
        valueProp: ProductLineProps.PRODUCT_LINE_ID
      },
      { resultWithinProp: ['result', 'productLines'], urlParams: { [RouteParams.ID]: id }, params: { 'status': ProductLinesConstant.productLinesStatus.ACTIVE, 'prodTypeCd': ProductLineType.VEH} }
    );
  }

  getProductLineByDistributorAndSourceLocation(id: number, idSL: string) {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.PRODUCT_LINES_BY_DISTRIBUTOR,
      {
        labelProp: ProductLineProps.PL_NAME,
        valueProp: ProductLineProps.PRODUCT_LINE_ID
      },
      { resultWithinProp: ['result', 'productLines'], urlParams: { [RouteParams.ID]: id }, params: { 'srcLocationCd': idSL, 'status': ProductLinesConstant.productLinesStatus.ACTIVE, 'prodTypeCd': ProductLineType.VEH} },
    );
  }
}
